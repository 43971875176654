import './App.css';
import { ThemeProvider } from '@emotion/react';
import createTheme from '@mui/material/styles/createTheme';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Button } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#FF0000',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={ { color: "white" } }>
        <div id="pageWrapper">
          <div id="sigil" className="sigil-bg" />
          <div id="footer">
            <Button component="a" target="_blank" href="https://bsky.app/profile/cadaverdoe.bsky.social" variant="text">
              <svg viewBox="0 -35 600 600" focusable="false" className='MuiSvgIcon-fontSizeMedium css-w6om1t-MuiSvgIcon-root'>
                <path d="m135.72 44.03c66.496 49.921 138.02 151.14 164.28 205.46 26.262-54.316 97.782-155.54 164.28-205.46 47.98-36.021 125.72-63.892 125.72 24.795 0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.3797-3.6904-10.832-3.7077-7.8964-0.0174-2.9357-1.1937 0.51669-3.7077 7.8964-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.4491-163.25-81.433-5.9562-21.282-16.111-152.36-16.111-170.07 0-88.687 77.742-60.816 125.72-24.795z" />
              </svg>
            </Button>
            <Button component="a" target="_blank" href="https://www.instagram.com/cadaverdoe" variant="text">
              <InstagramIcon />
            </Button>
            <Button component="a" target="_blank" href="https://twitter.com/CadaverDoe" variant="text">
              <TwitterIcon />
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

/*
<img src="hare.png" id="hare" alt="evil hare" />
  <CssBaseline />
  <h1>Grey h1 element</h1>
  <Button variant="text">Text</Button>
  <Button variant="contained">Contained</Button>
  <Button variant="outlined">Outlined</Button>
*/